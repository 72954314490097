import React from "react";
import assets from "../../assets/images";
import "./skills.scss";

const Skills = () => {
  return (
    <div className="container skills-component" id="skills">
      <div className="row">
        <h1 className="title">What I'm into?</h1>
        <p className="skills-info">
          The primary area of my interest is undoubtedly front-end. <br /> My
          technical proficiency is centered around industry-leading
          technologies, including React JS/TS, HTML5, Javascript, SCSS,
          Tailwind, and TypeScript, which I leverage to create innovative
          solutions and exceed client expectations. I also possess a
          comprehensive understanding of Networking, Azure DevOps, Figma,
          Angular, Vue Js, allowing me to tackle complex challenges with ease.
        </p>
        <div className="skills-wrapper">
          <div>
            <img src={assets.JsWhiteIcon} alt="js white logo" />
            <p>Javascript</p>
          </div>
          <div>
            <img src={assets.ReactWhiteIcon} alt="react white logo" />
            <p>React</p>
          </div>
          <div>
            <img src={assets.SassWhiteIcon} alt="sass white logo" />
            <p>Sass</p>
          </div>
          <div>
            <img src={assets.TsWhiteIcon} alt="ts white logo" />
            <p>Typescript</p>
          </div>
          <div>
            <img src={assets.GitWhiteIcon} alt="git white logo" />
            <p>Git</p>
          </div>
          <div>
            <img src={assets.AngularWhiteIcon} alt="angular white logo" />
            <p>Angular</p>
          </div>
          <div>
            <img src={assets.BootstrapWhiteIcon} alt="bootstrap white logo" />
            <p>Bootstrap</p>
          </div>
          <div>
            <img src={assets.NodeWhiteIcon} alt="node white logo" />
            <p>Node</p>
          </div>
          <div>
            <img src={assets.FigmaWhiteIcon} alt="figma white logo" />
            <p>Figma</p>
          </div>
          <div>
            <img src={assets.HtmlWhiteIcon} alt="html white logo" />
            <p>Html</p>
          </div>
          <div>
            <img src={assets.NetworkingWhiteIcon} alt="networking white logo" />
            <p>Networking</p>
          </div>
          <div>
            <img src={assets.TailwindWhiteIcon} alt="tailwind white logo" />
            <p>Tailwind</p>
          </div>
          <div>
            <img src={assets.VueWhiteIcon} alt="vue white logo" />
            <p>Vue</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
