import React from "react";

import "./productList.css";
import Product from "../product/Product";

const ProductList = () => {
  return (
    <div className="container moments-container" id="moments">
      <div className="row">
        <div className="col-12">
          <div className="pl" id="moments">
            <div className="pl-texts">
              <h1 className="pl-title">Aspire To Inspire</h1>
            </div>
            <Product />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
