import { createContext, useReducer, useEffect } from "react";

export const ThemeContext = createContext();

const INITIAL_STATE_THEME = { whiteThemeMode: false };

const themeReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE":
      return {
        whiteThemeMode: !state.whiteThemeMode,
      };
    default:
      return state;
  }
};

const getLocalThemeData = () => {
  const localData = localStorage.getItem("theme");
  return localData ? JSON.parse(localData) : [];
};

export const ThemeProvider = (props) => {
  const [state, dispatch] = useReducer(
    themeReducer,
    INITIAL_STATE_THEME,
    getLocalThemeData
  );

  useEffect(() => {
    localStorage.setItem("theme", JSON.stringify(state));
  }, [state]);

  return (
    <ThemeContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ThemeContext.Provider>
  );
};
