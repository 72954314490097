/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import "./product.scss";

const Product = () => {
  return (
    <div class="containers">
      <div class="carousel">
        <div class="carousel__face">
          <span></span>
        </div>
        <div class="carousel__face">
          <span></span>
        </div>
        <div class="carousel__face">
          <span></span>
        </div>
        <div class="carousel__face">
          <span></span>
        </div>
        <div class="carousel__face">
          <span></span>
        </div>
        <div class="carousel__face">
          <span></span>
        </div>
        <div class="carousel__face">
          <span></span>
        </div>
        <div class="carousel__face">
          <span></span>
        </div>
        <div class="carousel__face">
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default Product;
