import React from "react";
import "./footer.css";

const actualYear = new Date().getFullYear();

const Footer = () => {
  return (
    <div className='container footer-container'>
      <div className='row'>
        <div className='col-12 col-md-12'>
          <div className='copyrights'>
            <p>Copyright © {actualYear} - Xhonatan Hajdari</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
