import React, { useContext } from "react";
import "./toggle.css";

import { ThemeContext } from "../../context";

const Toggle = () => {
  const theme = useContext(ThemeContext);

  const handleClick = () => {
    theme.dispatch({ type: "TOGGLE" });
  };

  return (
    <div className="wrapper t">
      <input
        type="checkbox"
        id="hide-checkbox"
        checked={theme.state.whiteThemeMode}
        onChange={handleClick}
      />
      <label htmlFor="hide-checkbox" className="toggle">
        {/* Crater spans */}
        <span className="toggle-button">
          {[1, 2, 3, 4, 5, 6, 7].map((craterNumber) => (
            <span
              key={craterNumber}
              className={`crater crater-${craterNumber}`}
            ></span>
          ))}
        </span>
        {/* Star spans */}
        {[1, 2, 3, 4, 5, 6, 7, 8].map((starNumber) => (
          <span key={starNumber} className={`star star-${starNumber}`}></span>
        ))}
      </label>
    </div>
  );
};

export default Toggle;
