import React from "react";
import { ToastContainer } from "react-toastify";
import Toggle from "../toggle/Toggle";

const Header = () => {
  return (
    <>
      <ToastContainer />
      <Toggle />
    </>
  );
};

export default Header;
