import React from "react";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  MoveOut,
  Sticky,
  StickyIn,
  ZoomIn,
} from "react-scroll-motion";
import About from "../about/About";
import Contact from "../contact/Contact";
import Intro from "../intro/Intro";
import ProductList from "../productList/ProductList";
import Skills from "../skills/Skills";

const Body = () => {
  const ZoomInScrollOut = batch(
    StickyIn(),
    FadeIn(),
    ZoomIn(),
    MoveOut(0, -1000)
  );

  const FadeUp = batch(Fade(), MoveOut(-3000, 0), Sticky());

  return (
    <ScrollContainer>
      <ScrollPage>
        <Animator
          animation={batch(Fade(), Sticky(), MoveOut(0, -200))}
          style={{ width: "100%", height: "100%" }}
        >
          <Intro />
        </Animator>
      </ScrollPage>
      <ScrollPage>
        <Animator
          animation={ZoomInScrollOut}
          style={{ width: "100%", height: "100% !important" }}
        >
          <About />
        </Animator>
      </ScrollPage>
      <ScrollPage>
        <Animator
          animation={FadeUp}
          style={{ width: "100%", height: "100% !important" }}
        >
          <Skills />
        </Animator>
      </ScrollPage>
      <ScrollPage>
        <Animator
          animation={batch(Fade(), Sticky(), MoveOut(0))}
          style={{ width: "100%", height: "100%" }}
        >
          <ProductList />
        </Animator>
      </ScrollPage>
      <ScrollPage style={{ height: "456px" }}>
        <Contact />
      </ScrollPage>
    </ScrollContainer>
  );
};

export default Body;
