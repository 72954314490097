import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "./context";
import UI from "./components/UI/UI";
import smoothscroll from "smoothscroll-polyfill";
import Loader from "./components/loader/Loader";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [displayUi, setDisplayUi] = useState(false);

  const theme = useContext(ThemeContext);
  const whiteThemeMode = theme.state.whiteThemeMode;
  smoothscroll.polyfill();

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
      setDisplayUi(true);
    }, 5000);

    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor: whiteThemeMode ? "white" : "black",
        color: whiteThemeMode ? "black" : "white",
      }}
    >
      {loading && <Loader />}
      {displayUi && <UI />}
    </div>
  );
};

export default App;
