import React, { useState } from "react";
import Typewriter from "typewriter-effect";
import { useEffect, useRef } from "react";

import "../intro/intro.css";
import assets from "../../assets/images";

const Intro = () => {
  const introRef = useRef(null);
  const [isFadeIn, setIsFadeIn] = useState(false);

  useEffect(() => {
    const introElement = introRef.current;

    const introPosition = introElement.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;

    if (introPosition < windowHeight * 0.75) {
      setIsFadeIn(true);
    }
  }, []);
  return (
    <div
      className={`container intro-container${isFadeIn ? " fade-in" : ""}`}
      id="home"
      ref={introRef}
    >
      <div className="row">
        <div className="col-12 col-lg-6 i">
          <div className="i-left">
            <div className="i-left-wrapper">
              <h1 className="i-name">
                <span>My name is</span> Xhonatan Hajdari
              </h1>
              <h2 className="i-intro">
                <span>
                  <Typewriter
                    options={{
                      strings: [
                        "CyberSecurity Engineer",
                        "Software Developer",
                        "Speed Enthusiast",
                        "Traveler",
                        "Heliophile",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 i-second">
          <div className="i-right">
            <div className="i-bg"></div>
            <img src={assets.Me} alt="logo" className="i-img img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
