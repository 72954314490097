import React, { useEffect } from "react";
import Slider from "./Slider";

import "./about.css";

const About = () => {
  useEffect(() => {
    const aboutContainer = document.getElementById("about");
    const windowHeight = window.innerHeight;

    const handleScroll = () => {
      const rect = aboutContainer.getBoundingClientRect();
      const offset = windowHeight - rect.top;

      if (offset >= 0) {
        aboutContainer.classList.add("fade-in");
      } else {
        aboutContainer.classList.remove("fade-in");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="container about-container" id="about">
      <div className="row">
        <div className="col-12 col-lg-6 about-left fade-in">
          <div className="ipad">
            <Slider />
          </div>
        </div>
        <div className="col-12 col-lg-6 a-right fade-in">
          <h1 className="a-title">About Me</h1>
          <p className="a-desc">
            As a highly accomplished graduate of{" "}
            <strong>
              St. Petersburg State University of Telecommunications "Bonch
              Bruevich"
            </strong>{" "}
            in Information System Security, I possess a diverse range of
            certifications and achievements that reflect my expertise and
            passion for the field. Where throughout my career as a{" "}
            <strong>Frontend Software Developer</strong> , I have built a
            reputation as a high-performing professional, consistently
            delivering <strong>exceptional results</strong> on a variety of
            projects for a range of clients.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
