import React, { useEffect, useState } from "react";
import data from "../../sliderData";

import "./slider.css";

const Slider = () => {
  const [moments, setMoments] = useState(data);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = moments.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, moments]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 5000);
    return () => clearInterval(slider);
  }, [index]);

  return (
    <section className="slider-section">
      <div className="section-center">
        {moments.map((moments, momentsIndex) => {
          const { id, image } = moments;

          let position = "nextSlide";
          if (momentsIndex === index) {
            position = "activeSlide";
          }
          if (
            momentsIndex === index - 1 ||
            (index === 0 && momentsIndex === moments.length - 1)
          ) {
            position = "lastSlide";
          }

          return (
            <article key={id} className={position}>
              <img src={image} alt="" className="moments-img" />
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Slider;
