import React, { Fragment } from "react";
import "./loader.scss";

const Loader = () => {
  return (
    <Fragment>
      <div className="loading">
        <div className="loading-text">
          <span className="loading-text-words">X</span>
          <span className="loading-text-words">H</span>
          <span className="loading-text-words">O</span>
          <span className="loading-text-words">N</span>
          <span className="loading-text-words">A</span>
          <span className="loading-text-words">T</span>
          <span className="loading-text-words">A</span>
          <span className="loading-text-words">N</span>
          <span className="loading-text-words"></span>
          <span className="loading-text-words">H</span>
          <span className="loading-text-words">A</span>
          <span className="loading-text-words">J</span>
          <span className="loading-text-words">D</span>
          <span className="loading-text-words">A</span>
          <span className="loading-text-words">R</span>
          <span className="loading-text-words">I</span>
        </div>
      </div>
    </Fragment>
  );
};

export default Loader;
