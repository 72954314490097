import React from "react";
import Footer from "../footer/Footer";
import Body from "./Body";
import Header from "./Header";
import Particles from "../particles/particles";

const UI = () => {
  return (
    <>
      <Particles />
      <Header />
      <Body />
      <Footer />
    </>
  );
};

export default UI;
