import assets from "./assets/images";

const moments = [
  {
    id: 1,
    image: assets.ImageOne,
  },
  {
    id: 2,
    image: assets.ImageTwo,
  },
  {
    id: 3,
    image: assets.ImageThree,
  },
  {
    id: 4,
    image: assets.ImageFour,
  },
  {
    id: 5,
    image: assets.ImageFive,
  },
  {
    id: 6,
    image: assets.ImageSix,
  },
  {
    id: 7,
    image: assets.ImageSeven,
  },
  {
    id: 8,
    image: assets.ImageEight,
  },
  {
    id: 9,
    image: assets.ImageNine,
  },
  {
    id: 10,
    image: assets.ImageTen,
  },
  {
    id: 11,
    image: assets.ImageEleven,
  },
];

export default moments;
